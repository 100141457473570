import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '../components/Link';
import BarApp from '../components/BarApp';
import SEO from '../components/Seo';
import Footer from '../components/Footer';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Paper, Hidden } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import CssBaseline from '@material-ui/core/CssBaseline';

const useStyles = makeStyles((theme) => ({
  divInicio: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.9,
    minHeight: 640,
    backgroundImage: 'url(/static/images/home.webp)',
    backgroundColor: 'transparent',
    transition: 'opacity 0.5s ease 500ms',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    zIndex: 3

  },
  curba: {
    position: 'absolute',
    width: '100%',
    left: 0,
    zIndex: 1,
    lineHeight: 0,
    bottom: 0,
  },
  svgCurba: {
    fill: 'rgb(248, 248, 248)',
    height: '80px',
    width: '100%',
  },
  titulo: {
    paddingBottom: 30,
    color: 'white'
  },
  tipoSubTitulo: {
    fontSize: 40,
    color: 'white',
    lineHeight: 1.09,
    fontWeight: 100
  },
  tipoTitulo: {
    fontSize: 50,
    color: 'white',
    lineHeight: 1.09,
    fontWeight: 700
  },
  botones: {
    margin: 20,
    padding: 15
  },
  section: {
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: 'rgb(248, 248, 248)'
  },
  sectionWhite: {
    paddingTop: 70,
    paddingBottom: 40,
    backgroundColor: 'white',
    paddingLeft: 20,
    paddingRight: 20
  },
  sectionMin: {
    paddingTop: 50,
    paddingBottom: 30,
    backgroundColor: '#26BEC9',
    color: 'white'
  },
  servicioVerde: {
    //minHeight: 150,
    //display: 'flex',
    //alignItems: 'center',
    //backgroundColor: 'white',
    // #26BEC9 verde
    // #EC2E5D rosa
    minHeight: '100%',
    color: '#343a40',
    '&:hover': {
      backgroundColor: '#26BEC9',
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
      transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
      transform: 'translateY(-5px)',
      //background: rgb(0, 47, 166);
      //opacity: 1,
      color: 'white'
    }
  },
  servicioRosa: {
    //minHeight: 150,
    //display: 'flex',
    //alignItems: 'center',
    //backgroundColor: 'white',
    // #26BEC9 verde
    // #EC2E5D rosa
    minHeight: '100%',
    color: '#343a40',
    '&:hover': {
      backgroundColor: '#EC2E5D',
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
      transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
      transform: 'translateY(-5px)',
      //background: rgb(0, 47, 166);
      //opacity: 1,
      color: 'white'
    }
  },
  rootCard: {
    maxWidth: 345,
  },
  media: {
    height: 100,
  },
  textCard: {
    fontSize: 17
  },
  textoExperiencia: {
    fontSize: '12px',
    lineHeight: '2em',
    letterSpacing: '2px',
    color: 'rgb(171, 171, 171)',
    display: 'flex',
    alignItems: 'flex-end',
    textTransform: 'uppercase',
  },
  years: {
    fontSize: '180px',
    lineHeight: 0.8,
    fontWeight: 900,
    color: '#EC2E5D',
    margin: '0px 5px -5px -5px',
  },
  subTextoExperiencia: {
    fontSize: '32px',
    color: 'rgb(51, 51, 51)',
  },
  quantity: {
    fontSize: '40px',
    fontWeight: 400,
    lineHeight: 1,
    color: 'white',
    marginBottom: '12px',
  },
  quantityText: {
    marginBottom: '19px',
    fontSize: '14px',
    textTransform: 'uppercase',
    letterSpacing: '2px',
  },
  nuestraEmpresa: {
    color: 'rgb(171, 171, 171)',
    fontWeight: 400,
    marginTop: '-5px',
    marginBottom: '20px',
    textTransform: 'uppercase',
    fontSize: '14px',
    letterSpacing: '3px',
    lineHeight: 1.43,
  },
  sectionTipoProduct: {
    position: 'relative',
    width: '100%',
    backgroundColor: 'transparent',
    marginTop: -130,
    zIndex: 3,
    display: 'block',
    padding: 40,
  },
  paperProductVerde: {
    //minHeight: 150,
    //display: 'flex',
    //alignItems: 'center',
    //backgroundColor: 'white',
    // #26BEC9 verde
    // #EC2E5D rosa
    padding: 15,
    minHeight: '100%',
    color: '#343a40',
    '&:hover': {
      backgroundColor: '#26BEC9',
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
      transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
      transform: 'translateY(-5px)',
      //background: rgb(0, 47, 166);
      //opacity: 1,
      color: 'white'
    }
  },
  paperProductRosa: {
    //minHeight: 150,
    //display: 'flex',
    //alignItems: 'center',
    //backgroundColor: 'white',
    // #26BEC9 verde
    // #EC2E5D rosa
    padding: 15,
    minHeight: '100%',
    color: '#343a40',
    '&:hover': {
      backgroundColor: '#EC2E5D',
      boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
      transition: 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
      transform: 'translateY(-5px)',
      //background: rgb(0, 47, 166);
      //opacity: 1,
      color: 'white'
    }
  },
  icono: {
    fontSize: 80
  },
  divContacto: {
    color: 'white',
    paddingTop: 80,
    paddingBottom: 80,
    backgroundPosition: '80% 0%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    backgroundColor: '#006f77',
    backgroundImage: 'url(static/images/mapa.png)',
  },
  bloqueTexto: {
    borderStyle: 'none none none solid',
    padding: 15,
    borderColor: 'white',
  },
  valores: {
    marginTop: '30px',
    padding: '36px 10px 26px',
    borderStyle: 'solid',
    borderColor: 'rgb(238, 238, 238)',
    borderWidth: '2px',
    borderImage: 'initial',
    transition: 'all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1) 0s',
    '&:hover': {
      transform: 'translateY(-5px)',
      borderWidth: '0px',
      boxShadow: 'rgba(51, 51, 51, 0.1) 0px 0px 40px',
    }
  }
}));


export default function Index(props) {

  const classes = useStyles();

  return (
    <React.Fragment>

      <SEO
        title={'Inicio | Velvet Consultores '}
        description={'Empresa especializada en consultoría empresarial y personal'}
        image={'/static/images/home.webp'}
        siteUrl={'https://corporacionvelvet.com'}
        pathname={'index'}
        article
      />
      <CssBaseline />
      <BarApp />
      <main className={classes.main} style={{ backgroundColor: '#F8F8F8' }}>
        <div className={classes.divInicio}>
          <Container maxWidth='sm'>
            <div className={classes.titulo}>

              <Typography align='center' variant='h4' className={classes.tipoSubTitulo}>
                La solución perfecta para
            </Typography  >
              <br />
              <Typography align='center' variant='h3' className={classes.tipoTitulo}>
                TI O TU NEGOCIO
              </Typography>


            </div>
            <div align='center'>
              <a href='tel:961970025' style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button variant='contained' className={classes.botones} size='large' color='secondary'>
                  Consulta Gratis
              </Button>
              </a>
            </div>

          </Container>
          <div className={classes.curba}>
            <svg className={classes.svgCurba} xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 100 100">
              <path d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"></path>
            </svg>
          </div>
        </div>
        <div className={classes.sectionTipoProduct}>
          <div style={{ position: 'relative' }}>

            <Container maxWidth='sm'>
              <Grid container direction='row' justify='center' alignItems='center' spacing={4}>
                <Grid item xs={12} md={6}>
                  <a href='#personas' style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Paper className={classes.paperProductRosa}>
                      <PersonIcon className={classes.icono} />
                      <Typography variant='h6' >
                        Soluciones para Personas
                    </Typography>
                    </Paper>
                  </a>
                </Grid>
                <Grid item xs={12} md={6}>
                  <a href='#empresas' style={{ textDecoration: 'none', color: 'inherit' }}>

                    <Paper className={classes.paperProductVerde}>
                      <BusinessIcon className={classes.icono} />
                      <Typography variant='h6'>
                        Soluciones para Empresas
                    </Typography>
                    </Paper>
                  </a>
                </Grid>

              </Grid>

            </Container>

          </div>
        </div>

        <div className={classes.section}>
          <Container maxWidth='sm'>
            <Typography align='center' className={classes.nuestraEmpresa}>
              NUESTRA COMPAÑÍA
            </Typography>
            <Typography variant='h4' align='center'>
              Realizamos todo tipo de consultoría que prometen su éxito.
            </Typography>
            <br />
          </Container>
          <Container maxWidth='md'>

            <Grid container direction='row' justify='center' alignItems='center' spacing={0}>
              <Grid item xs={12} sm={12} md={6} lg={4} >

                <h2 className={classes.textoExperiencia}>
                  <Typography style={{ verticalAlign: 'inherit' }}>
                    <Typography style={{ verticalAlign: 'inherit' }} className={classes.years}>04</Typography>
                  </Typography>
                  <Typography style={{ verticalAlign: 'inherit' }}><Typography style={{ verticalAlign: 'inherit' }}> AÑOS DE EXPERIENCIA EN CONSULTORÍA</Typography>
                  </Typography>
                </h2>
                <h3 className={classes.subTextoExperiencia}>
                  <font style={{ verticalAlign: 'inherit' }}>
                    <font style={{ verticalAlign: 'inherit' }}>Sé parte de nuestras </font>
                  </font>
                  <span>
                    <font style={{ verticalAlign: 'inherit' }}>
                      <font style={{ verticalAlign: 'inherit' }}>historias de éxito</font>
                    </font>
                  </span>
                </h3>
              </Grid>
              <Hidden only={['xs', 'sm']}>
                <Grid item xs={12} sm={1}>
                </Grid>
              </Hidden>
              <Grid item xs={12} sm={12} md={6} lg={7}>
                <Grid container direction='row' justify='center' alignItems='center' spacing={2} >
                  <Grid item xs={12} >
                    <div align='center'>
                      <Typography align='left' variant='subtitle1'>
                        Ofrecemos servicios con profesionales
                        especializados aportando soluciones
                        prácticas y acertadas a nuestros clientes. Servicios consolidados que
                        atienden a necesidades puntuales o genéricas que se manifiestan en las
                        diferentes áreas de las organizaciones: producción, personal, administración,
                        comercial o de dirección, en su gestión global.
                      </Typography>
                    </div>
                  </Grid>
                </Grid>

              </Grid>

            </Grid>
          </Container>
        </div>
        <div className={classes.sectionMin}>
          <Container maxWidth='lg'>
            <Grid container direction='row' justify='center' alignItems='center' spacing={8}>
              <Grid container direction='row' justify='center' alignItems='center' spacing={2} >
                <Grid item xs={12} sm={6} md={3}>
                  <div align='center'>
                    <Typography align='center' className={classes.quantity}>
                      25
                      </Typography>
                    <Typography varian='h6' align='center' className={classes.quantityText}>
                      Empresas felices
                      </Typography>
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <div>
                    <Typography align='center' className={classes.quantity}>
                      1500
                      </Typography>
                    <Typography varian='h6' align='center' className={classes.quantityText}>
                      Personas capacitadas
                      </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div>
                    <Typography align='center' className={classes.quantity}>
                      500
                      </Typography>
                    <Typography varian='h6' align='center' className={classes.quantityText}>
                      Procesos exitosos
                      </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div>
                    <Typography align='center' className={classes.quantity}>
                      2500
                      </Typography>
                    <Typography varian='h6' align='center' className={classes.quantityText}>
                      Personas reclutadas
                      </Typography>
                  </div>
                </Grid>

              </Grid>

            </Grid>
          </Container>
        </div>

        <div className={classes.sectionWhite}>
          <Container maxWidth='sm'>
            <Typography align='center' variant='h4'>
              NUESTROS SERVICIOS
            </Typography>
            <br />
            <Typography align='center' variant='subtitle1'>
              Brindamos asesorías integrales en diversas áreas profesionales para su empresa o su desarrollo profesional.
            </Typography>
            <br />
            <br />
          </Container>

          <Container maxWidth='sm' id='empresas'>
            <Typography align='center' variant='h4'>
              <BusinessIcon className={classes.icono} />
            </Typography>
            <Typography align='center' variant='h6'>
              EMPRESAS
            </Typography>
            <br />
            <br />
          </Container>
          <Container maxWidth='lg' >
            <Grid container direction='row' justify='center' alignItems='center' spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card className={classes.servicioVerde}>
                  <Link to='seleccion-y-headhunting' style={{ textDecoration: 'none', color: 'inherit' }}>

                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image="static/images/seleccion.png"
                        title="Contemplative Reptile"
                      />
                      <CardContent>

                        <Typography variant="h6" component="h2" className={classes.textCard}>
                          Selección y Headhunting
                      </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Link>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card className={classes.servicioVerde}>
                  <Link to='asesoria-legal' style={{ textDecoration: 'none', color: 'inherit' }}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image="static/images/asesoriaLaboral.png"
                        title="Contemplative Reptile"
                      />
                      <CardContent>
                        <Typography variant="h6" component="h2" className={classes.textCard}>
                          Asesoría Legal - Laboral
                      </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Link>
                </Card>
              </Grid>
              {/*
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card className={classes.servicioVerde}>
                  <Link to='asesoria-contable-tributaria' style={{ textDecoration: 'none', color: 'inherit' }}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image="static/images/asesoriaContable.png"
                        title="Contemplative Reptile"
                      />
                      <CardContent>
                        <Typography variant="h6" component="h2" className={classes.textCard}>
                          Asesoria Contable y Tributaria
                      </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Link>
                </Card>
              </Grid>
              */}
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card className={classes.servicioVerde}>
                  <Link to='formacion-del-talento' style={{ textDecoration: 'none', color: 'inherit' }}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image="static/images/formacionDeTalentos.png"
                        title="Contemplative Reptile"
                      />
                      <CardContent>
                        <Typography variant="h6" component="h2" className={classes.textCard}>
                          Formación del Talento
                      </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Link>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <Card className={classes.servicioVerde}>
                  <Link to='desarrollo-e-integracion-organizacional' style={{ textDecoration: 'none', color: 'inherit' }}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image="static/images/desarrolloOrganizacional.png"
                        title="Contemplative Reptile"
                      />
                      <CardContent>
                        <Typography variant="h6" component="h2" className={classes.textCard}>
                          Desarrollo e Integración Organizacional
                      </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Link>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card className={classes.servicioVerde}>
                  <Link to='administracion-de-personal' style={{ textDecoration: 'none', color: 'inherit' }}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image="static/images/adminitracionDePersonal.png"
                        title="Contemplative Reptile"
                      />
                      <CardContent>
                        <Typography variant="h6" component="h2" className={classes.textCard}>
                          Administración de Personal
                      </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Link>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card className={classes.servicioVerde}>
                  <Link to='administracion-de-personal' style={{ textDecoration: 'none', color: 'inherit' }}>
                    <CardActionArea>
                      <CardMedia
                        className={classes.media}
                        image="static/images/background/salud-mental.jpg"
                        title="Programa de Salud Mental"
                      />
                      <CardContent>
                        <Typography variant="h6" component="h2" className={classes.textCard}>
                          Programa de Salud Mental
                      </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Link>
                </Card>
              </Grid>

            </Grid>
          </Container>
          <br />
          <br />
          <Container maxWidth='sm' id='personas'>
            <Typography align='center' variant='h4'>
              <PersonIcon className={classes.icono} />
            </Typography>
            <Typography align='center' variant='h6'>
              PERSONAS
            </Typography>
            <br />
            <br />
          </Container>
          <Container maxWidth='lg' >
            <Grid container direction='row' justify='center' alignItems='center' spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card className={classes.servicioRosa}>
                  <CardActionArea>
                    <Link to='empleabilidad-y-desarrollo-profesional' style={{ textDecoration: 'none', color: 'inherit' }}>

                      <CardMedia
                        className={classes.media}
                        image="static/images/empleabilidad.png"
                        title="Contemplative Reptile"
                      />
                      <CardContent>

                        <Typography variant="h6" component="h2" className={classes.textCard}>
                          Empleabilidad y Desarrollo Profesional
                      </Typography>
                      </CardContent>
                    </Link>
                  </CardActionArea>
                </Card>
              </Grid>

            </Grid>
          </Container>
          <br />
          <br />
        </div>

        <div className={classes.sectionWhite}>
          <Container maxWidth='sm'>
            <Typography align='center' variant='h4'>
              NUESTRO VALOR AGREGADO
            </Typography>
            <br />
          </Container>
          <Container maxWidth='md'>
            <Grid container direction='row' justify='center' alignItems='center' spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper className={classes.valores}>
                  <div align='center'>
                    <img src='static/images/equipoDinamico.png' style={{ width: 60, height: 60 }} />
                  </div>
                  <br />
                  <div>
                    <Typography variant='h6' align='center'>
                      Equipo Dinámico
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper className={classes.valores}>
                  <div align='center'>
                    <img src='static/images/pasion.png' style={{ width: 60, height: 60 }} />
                  </div>
                  <br />
                  <div>
                    <Typography variant='h6' align='center'>
                      Pasión
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper className={classes.valores}>
                  <div align='center'>
                    <img src='static/images/rapidez.png' style={{ width: 60, height: 60 }} />
                  </div>
                  <br />
                  <div>
                    <Typography variant='h6' align='center'>
                      Rapidez y Eficacia
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper className={classes.valores}>
                  <div align='center'>
                    <img src='static/images/networking.png' style={{ width: 60, height: 60 }} />
                  </div>
                  <br />
                  <div>
                    <Typography variant='h6' align='center'>
                      Networking
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper className={classes.valores}>
                  <div align='center'>
                    <img src='static/images/fiabilidad.png' style={{ width: 60, height: 60 }} />
                  </div>
                  <br />
                  <div>
                    <Typography variant='h6' align='center'>
                      Fiabilidad
                    </Typography>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Paper className={classes.valores}>
                  <div align='center'>
                    <img src='static/images/acompanamiento.png' style={{ width: 60, height: 60 }} />
                  </div>
                  <br />
                  <div>
                    <Typography variant='h6' align='center'>
                      Acompañamiento
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className={classes.divContacto}>
          <Container maxWidth='md'>
            <Grid container direction='row' justify='center' alignItems='center' spacing={3}>
              <Grid item xs={12} md={6}>
                <div align='left' className={classes.bloqueTexto}>
                  <Typography variant='h4'>
                    Para obtener más información, póngase en contacto con nuestros expertos.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div align='center'>
                  <a href='tel:961970025' style={{ textDecoration: 'none', color: 'white' }}>
                    <PhoneIcon style={{ fontSize: 50 }} />
                  </a>
                  <br />
                  <Typography variant='h6'>
                    ¡LLAMANOS!
                  </Typography>
                  <a href='tel:936164464' style={{ textDecoration: 'none', color: 'white' }}>
                    <Typography variant='h4' style={{ fontWeight: 700, lineHeight: 1.41, marginTop: 10 }}>
                      (51) 961 970 025
                    </Typography>
                  </a>
                </div>
              </Grid>

            </Grid>
          </Container>

        </div>

      </main>
      <Footer />
    </React.Fragment>
  );
}
